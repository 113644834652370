@font-face {
font-family: '__titleFont_9cb8ec';
src: url(/_next/static/media/f227c69dd1c06d7a-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__titleFont_Fallback_9cb8ec';src: local("Arial");ascent-override: 92.16%;descent-override: 49.02%;line-gap-override: 0.00%;size-adjust: 100.37%
}.__className_9cb8ec {font-family: '__titleFont_9cb8ec', '__titleFont_Fallback_9cb8ec'
}

@font-face {
font-family: '__bodyFont_5680f0';
src: url(/_next/static/media/8905eb09c17ef63f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100;
font-style: thin;
}

@font-face {
font-family: '__bodyFont_5680f0';
src: url(/_next/static/media/954af7fdab7bb91e-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__bodyFont_5680f0';
src: url(/_next/static/media/54e1e1dfd4d54334-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__bodyFont_Fallback_5680f0';src: local("Arial");ascent-override: 74.87%;descent-override: 18.83%;line-gap-override: 24.08%;size-adjust: 106.72%
}.__className_5680f0 {font-family: '__bodyFont_5680f0', '__bodyFont_Fallback_5680f0'
}

